
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of Assigned Cases</h5>
      <div class="row gy-2 justify-content-between">
        <div class="col-sm-12">
          <div class="input-group mb-0">
            <b-form-select style="max-width:80px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(case)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.case_id)" 
                    v-if="data.item.court_case" class="cursor-pointer">
                    <span v-html="statusBadge(data.item.court_case.status.status)"></span>
                    <h6 class="mb-1 mt-2">{{ data.item.court_case.case_number }}</h6>
                    <span class="text-dark font-sm text-capitalize">{{ data.item.court_case.parties }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div style="min-width:4rem;" @click="navigateTo(data.item.case_id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)"></span>
                </div>
              </template>
              <template v-slot:cell(duty)="data">
                <div v-if="data.item.duty" @click="navigateTo(data.item.case_id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.duty.title }}</span>
                </div>
              </template>
              <template v-slot:cell(assigned_at)="data">
                <div style="width:7rem;" class="cursor-pointer">
                  {{$filters.date(data.item.assigned_at, 1) }} 
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "lawyer-cases",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "case",
          label: "Case",
        },
        {
          key: "duty",
          label: "Duty",
        },
        {
          key: "status",
          label: "Assigned Status",
        },
        {
          key: "assigned_at",
          label: "Assigned date",
        },
      ],
      pageData:{
        data: []
      }
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0';
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/cases/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
                    lawyer_id: this.$route.params.userId }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/users/assigned-cases?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    initializeAll(){
    }
  },
  created() {
    this.fetchItems()
  }
}

</script>

